* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Trebuchet MS", sans-serif;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0 0 0;
}

.contact-form {
  max-width: 600px !important;
}

.form {
  padding: 40px 0;
}

.contact-form h4 {
  color: gray;
}

.nav-link-text {
  color: #000 !important;
  font-size: large !important;
}

.nav-link-text:hover {
  color: red !important;
}

.link {
  text-decoration: none;
}

.navbar-toggler {
  border: none !important;
  box-shadow: none !important;
}

